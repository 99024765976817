import type { I18nOptions } from "vue-i18n";

export default {
  legacy: false,
  locale: "de",
  fallbackLocale: "de",
  globalInjection: false,
  missingWarn: false,
  fallbackWarn: false,
} as I18nOptions;
